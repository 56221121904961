
declare var window: any;
import {
  ReloadOutlined,
  UserOutlined,
  LockOutlined,
} from "@ant-design/icons-vue";
import { Options, Vue } from "vue-class-component";
import QRCode from "qrcode";

import { notification } from "ant-design-vue";
import {
  BehaviorSubject,
  from,
  interval,
  Observable,
  Subscription,
} from "rxjs";
import { Ricent } from "@/core/ricent";
import { filter, retry, switchMap, tap } from "rxjs/operators";
import { Modal } from "ant-design-vue";
import {
  RuleObject,
  ValidateErrorEntity,
} from "ant-design-vue/lib/form/interface";

interface FormState {
  user: string;
  password: string;
}

interface FormTelState {
  tel: string;
  validCode: string;
}

const duration = 60;

@Options({
  components: {
    ReloadOutlined,
    UserOutlined,
    LockOutlined,
  },
})
export default class Login extends Vue {
  formState: FormState = {
    user: "",
    password: "",
  };

  formTelState: FormTelState = {
    tel: "",
    validCode: "",
  };

  get appName() {
    return process.env.VUE_APP_Name;
  }
  identityCode: BehaviorSubject<string> = new BehaviorSubject(
    this._generatorCode()
  );
  checkeing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  agree: boolean = true;
  activeTab: number = 1;
  public auto: boolean = true;
  loginLoading: boolean = false;
  src: string | null = null;
  subscriptions: Subscription[] = [];
  isLapse: boolean = false;
  isLogin: boolean = false;
  loginFlags: number = 1;

  event: any = [];
  rules = {
    tel: [
      {
        validator: (rule: RuleObject, val: string, fn: any) => {
          let value: string = val || this.formTelState.tel;
          if (!value || !/^1[0-9][0-9]\d{8}$/.test(value)) {
            return Promise.reject("请输入正确的手机号码");
          }

          return Promise.resolve();
        },
        trigger: "blur",
      },
    ],
  };

  beforeUnmount() {
    this.subscriptions.forEach((_) => {
      _.unsubscribe();
    });
    document.removeEventListener("keydown", this.onkeydown);
  }

  _generatorCode() {
    return `sass-auth-${Ricent.uuid()}`;
  }

  onkeydown() {
    var key = window.event.keyCode;
    if (key == 13) {
      if (this.activeTab == 1) {
        if (this.formState.user && this.formState.password) {
          this.handleFinish();
        }
      } else {
        if (this.formTelState.tel && this.formTelState.validCode) {
          let form: any = this.$refs.vcodeFormRef;
          form.validateFields(["tel"]).then((_: any) => {
            this.handleFinish();
          });
        }
      }
    }
  }

  created() {
    document.addEventListener("keydown", this.onkeydown);
    // document.onkeydown = function (e) {
    //   var key = window.event.keyCode;
    //   if (key == 13) {
    //     console.log(key);
    //   }
    // };
    this.subscriptions = [
      this.identityCode
        .pipe(
          tap((_) => {
            this.src = null;
            this.checkeing.next(false);
          }),
          switchMap((_) => {
            return from(
              this.$api.authCode.post({
                authCode: _,
              })
            ).pipe(
              switchMap(() => {
                return from(
                  this.$api.shortLink.post({
                    type: 2, //1 链接 2 json
                    remark: "登录生成二维码",
                    data: JSON.stringify({
                      command: "auth_login",
                      authCode: _,
                      form: "browser",
                    }),
                    // expireAt: this.auto ? 15 * 24 : -1, //过期时间15天或者永远不过期
                    expireAt: 15 * 24, //过期时间15天或者永远不过期
                    allowAnonymous: false,
                  })
                );
              }),
              retry(2)
            );
          })
        )
        .subscribe(
          (_) => {
            this.checkeing.next(true);
            if (_?.data?.url) {
              this.isLapse = false;
              QRCode.toDataURL(_?.data?.url, {
                width: 240,
              }).then((url) => {
                this.src = url;
              });
            }
          },
          (err) => {
            notification.error({
              message: "二维码生成错误",
              description: err?.toString() || "",
            });
          }
        ),
      interval(1500)
        .pipe(
          switchMap((_) => this.checkeing),
          filter((_) => {
            return !!_;
          }),
          switchMap((_) => {
            return this.identityCode;
          }),
          switchMap((_) => {
            return from(this.$api.authCode.get(_));
          })
        )
        .subscribe(
          (_) => {
            if (_.code == "404") {
              //刷新二维码
              this.isLapse = true;
              this.checkeing.next(false);
            }
            if (_.code == "202" || _.data?.status == 4) {
              this.checkeing.next(false);
              this.$auth.tokenInfo = {
                token: _.data?.token,
              };
              this.isLogin = true;
              this.$auth
                .profile()
                .then((_) => {
                  return this.$auth.getOrgs();
                })
                .then((_) => {
                  //重新注册路由
                  // registerRouter();
                  this.$router.push({ path: "/organize" });
                });
            }
          },
          (err) => {
            notification.error({
              message: "状态轮询错误",
              description: err?.toString() || "",
            });
          }
        ),
    ];
  }

  loading: boolean = false;
  handleFinish(e?: any) {
    e && e.preventDefault();
    this.loading = true;
    let done = (_: any) => {
      this.$auth.tokenInfo = {
        token: _.data?.accessToken || (<any>_.data).access_token,
      };
      return this.$auth
        .profile()
        .then((_) => {
          //没有名字默认给手机名字
          if (!_.data?.name) {
            _.data!.name = _.data?.phone || _.data?.userName;
            return this.$api.userCenter.put(_.data!.id!, _.data).then((r) => {
              this.$auth.userInfo = _.data!;
              return r;
            });
          }
          return Promise.resolve(_);
        })
        .then((_) => {
          return this.$auth.getOrgs();
        })
        .then((orgs) => {
          if (orgs.length) {
            return Promise.resolve(orgs);
          }
          return this.$api.org
            .queryMeJoin({
              joinType: 1,
              status: 1,
            })
            .then((res) => {
              //如果有邀请记录,就接受所有的邀请
              if (res.data && res.data.length) {
                return Promise.all(
                  res.data.map((_) => {
                    return this.$api.org.approvalJoinForUser(_.id!, <any>{
                      status: 2,
                    });
                  })
                ).then((_) => {
                  return this.$auth.getOrgs();
                });
              } else {
                return orgs;
              }
            });
          //没有组织查看申请邀请记录
        })
        .then((_) => {
          //重新注册路由
          if (_?.length) {
            this.$router.push({ path: "/organize" });
          } else {
            this.$auth.userInfo = null;
            this.$auth.tokenInfo = null;
            notification.warn({
              message: "提示",
              description:
                "您不属于任何的组织、请登入app创建自己的组织或申请加入其他组织",
            });
          }
        });
    };

    if (this.activeTab == 1) {
      this.checkeing.next(false);
      this.$api.auth
        .login(this.formState.user, "password", this.formState.password)
        .then((_) => {
          done(_);
        })
        .catch((ex) => {
          this.loading = false;
          this.checkeing.next(true);
        });
    } else {
      let form: any = this.$refs.vcodeFormRef;
      form
        .validate()
        .then((_: any) => {
          return this.$api.auth.smsValidate(
            {
              phone: this.formTelState.tel,
              code: this.formTelState.validCode,
            },
            { isAnonymous: true }
          );
        })
        .then((_: any) => {
          done(_);
        })
        .catch((error: ValidateErrorEntity<FormState>) => {
          this.loading = false;
          this.checkeing.next(true);
        });
    }
  }

  doRefresh() {
    this.identityCode.next(this._generatorCode());
  }

  sendWaiting = 0;
  getValidCode() {
    let form: any = this.$refs.vcodeFormRef;
    form
      .validateFields(["tel"])
      .then((_: any) => {
        if (this.sendWaiting > 0) return;
        if (this.sendWaiting == 0) {
          this.sendWaiting = duration;
        }
        let run = () => {
          setTimeout(() => {
            this.sendWaiting--;
            if (this.sendWaiting > 0) {
              run();
            }
          }, 1000);
        };
        run();
        this.$api.auth
          .smsSend(
            {
              phone: this.formTelState.tel,
            },
            {
              isAnonymous: true,
            }
          )
          .then(
            (_) => {
              console.log(_);
            },
            (err) => {
              console.log(err);
            }
          );
        console.log(_);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
}
